import React, { useRef, useEffect, useState } from 'react'
import MastHead from '@components/MastHead/'
import Layout from '@components/layout'
import SEO from '@components/seo'
import StoriesComponent from '@components/Stories'
import partners from '@data/partners.json'

const IndexPage = () => {
  const sloganRef = useRef<HTMLHeadingElement>(null)
  const [mastHeight, setMastHeight] = useState(null)

  useEffect(() => {
    const sloganHeight =
      sloganRef && sloganRef.current ? sloganRef.current.offsetHeight : 0

    const height = window.innerHeight - sloganHeight - 124
    setMastHeight(height)
  }, [sloganRef])

  return (
    <Layout className="homepage">
      <>
        <a className="skip-main" href="#intro">
          Direct naar content
        </a>
        <a className="skip-main" href="#stories">
          Direct naar verhalen
        </a>
        <a className="skip-main " href="#features">
          Direct Naar campagne informatie
        </a>
        <a className="skip-main" href="#partnerships">
          Direct naar partners
        </a>

        <SEO title="Home" />
        <MastHead
          homePage={false}
          height={mastHeight}
          article={'assets/images/techniek-1.jpg'}
          title={'Techniek is de toekomst'}
        />
        <section id="intro" className="padding">
          <h2 ref={sloganRef} className="slogan">
            <span className="werken">Techniek is de toekomst.</span>
          </h2>
          <p className="subtitle">
            Als je werkt in het techniekonderwijs, sta je aan de basis van heel
            veel toekomst. Samen met jonge mensen denk je na over
            energiemanagement en over hoe techniek het klimaat kan verduurzamen.
            Maar ook over cybersecurity, drones, autotechniek en het bouwen van
            huizen. Slimme constructies, nieuwe materialen, automatische
            bezorging en elektrische systemen. Maar bovenal sta je aan de wieg
            van de persoonlijke ideeën, dromen en idealen van een nieuwe
            generatie. Een generatie die met jouw kennis en inspiratie de
            toekomst in handen heeft.
          </p>
        </section>

        <section id="video">
          <div className="video">
            <div className="video-wrapper">
              <iframe
                title="Werken in het techniekonderwijs. De baan van het leven."
                src="https://www.youtube.com/embed/Wr2D8xEFy4U;disablekb=1"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              ></iframe>
            </div>
          </div>
          <div className="subline padding">
            <p>
              Deze video is tot stand gekomen met hulp van Teylingen College -
              KTS.
            </p>
          </div>
        </section>

        {/* <section id="stories">
          <h2 className="padding">
            Verhalen uit <span className="het_onderwijs">het onderwijs</span>
          </h2>
          <div className="no-padding">
            <StoriesComponent />
          </div>
        </section> */}

        <section>
          {/* <div className="features">
            <div id="share" className="feature light right">
              <div className="feature-col">
                <div className="feature-content">
                  <h2 className="feature-title">Deel jouw verhaal</h2>
                  <p>
                    Wil jij ook laten zien hoe mooi, confronterend, dankbaar,
                    spannend, simpel en ingewikkeld werken in het onderwijs is?
                    Deel dan jouw verhaal uit het onderwijs via social media met
                    #debaanvanhetleven. Ook kun je de verhalen, video’s, foto’s
                    en teksten gebruiken uit de online toolkit.
                  </p>
                  <a
                    title="naar toolkit"
                    href="https://campagnetoolkits.nl/debaanvanhetleven"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="button dark"
                  >
                    <span className="button__text">Naar toolkit</span>

                    <svg
                      className="icon"
                      width="26px"
                      height="16px"
                      viewBox="0 0 68 51"
                    >
                      <use xlinkHref="#svg-arrow" />
                    </svg>
                  </a>
                </div>
              </div>

              <div
                className="feature-col img img--schilderen"
                title="Meisje aan het schilderen"
                style={{ backgroundImage: `url('/assets/images/twerken.jpg'` }}
              ></div>
            </div> */}

          <div className="features">
            <div id="share" className="feature dark right">
              <div className="feature-col">
                <div className="feature-content">
                  <h2 className="feature-title">
                    Jouw kennis kan de toekomst verder helpen
                  </h2>
                  <p>
                    Werk jij al jaren als engineer, timmerman of in de
                    elektrotechniek en wordt het tijd voor iets nieuws? Als
                    leraar techniek, onderwijsondersteuner of gastdocent op het
                    vmbo kun je je kennis overdragen aan de volgende generatie.
                    Techniek is de basis van de maatschappij. Er is bijna geen
                    toepassing te bedenken of er is techniek in verwerkt dat
                    onderhouden moet worden. Wil je meer weten over wat werken
                    in het techniekonderwijs precies inhoudt en hoe het
                    zij-instroomtraject in zijn werk gaat?
                  </p>
                  <a
                    title="ontdek meer"
                    href="https://www.rijksoverheid.nl/onderwerpen/werken-in-het-onderwijs/vraag-en-antwoord/leraar-voortgezet-onderwijs"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="button dark"
                  >
                    <span className="button__text">Ontdek meer</span>

                    <svg
                      className="icon"
                      width="26px"
                      height="16px"
                      viewBox="0 0 68 51"
                    >
                      <use xlinkHref="#svg-arrow" />
                    </svg>
                  </a>
                </div>
              </div>

              <div
                className="feature-col img img--jongen"
                title="jongen in werkruimte"
                style={{
                  backgroundImage: `url('/assets/images/techniek-jongen-1.jpg'`,
                }}
              >
                <div className="overlay"></div>
                <blockquote class="blockquote ">
                  Wil jij zij-instromen?
                </blockquote>
              </div>
            </div>

            <div className="feature light left" id="features">
              <div className="feature-col">
                <div className="feature-content feature-content__left-column">
                  <h2>
                    <span className="feature-content__span">
                      Wordt techniek jouw toekomst?
                    </span>
                  </h2>
                  <p>
                    Heb jij een liefde voor techniek en zoek je een
                    vervolgopleiding met toekomst? Dan is de opleiding leraar
                    techniek misschien iets voor jou. Op de opleiding ga je aan
                    de slag in de grenzeloze wereld van techniek en leer je
                    naast kennis en kunde jouw talent voor techniek over te
                    brengen aan anderen. Ben je benieuwd wat de opleiding je nog
                    meer te bieden heeft?
                  </p>
                  <a
                    title="ontdek meer"
                    href="https://www.rijksoverheid.nl/onderwerpen/werken-in-het-onderwijs/vraag-en-antwoord/leraar-voortgezet-onderwijs"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="button dark"
                  >
                    <span className="button__text">Ontdek meer</span>
                    <svg
                      className="icon"
                      width="26px"
                      height="16px"
                      viewBox="0 0 68 51"
                    >
                      <use xlinkHref="#svg-arrow" />
                    </svg>
                  </a>
                </div>
              </div>

              <div
                title="meisje groene true"
                className="feature-col img img--meisje-groene-trui-1.jpg"
              >
                <div className="overlay"></div>
                <blockquote class="blockquote blockquote__left-column ">
                  <span class="blockquote__span">
                    Kies de studie leraar techniek.
                  </span>
                </blockquote>
                <div
                  className="img__inner"
                  style={{
                    backgroundImage: `url('/assets/images/meisje-groene-trui-1.jpg')`,
                  }}
                ></div>
              </div>
            </div>

            <div id="share" className="feature dark right">
              <div className="feature-col">
                <div className="feature-content">
                  <h2 className="feature-title">
                    Doe de kopopleiding techniek
                  </h2>
                  <p>
                    Studeer je techniek, maar zoek je meer dan werken voor een
                    techniekbedrijf? Word docent techniek op het vmbo. Daar werk
                    je niet alleen aan de toekomst van klimaat, drones en
                    materialen, maar sta je bovenal aan de basis van de toekomst
                    van een nieuwe generatie. Met de Kopopleiding kun je in een
                    jaar al voor de klas staan. Ben je benieuwd wat de
                    Kopopleiding precies inhoudt?
                  </p>
                  <a
                    title="ontdek meer"
                    href="https://www.rijksoverheid.nl/onderwerpen/werken-in-het-onderwijs/vraag-en-antwoord/leraar-voortgezet-onderwijs"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="button dark"
                  >
                    <span className="button__text">Ontdek meer</span>

                    <svg
                      className="icon"
                      width="26px"
                      height="16px"
                      viewBox="0 0 68 51"
                    >
                      <use xlinkHref="#svg-arrow" />
                    </svg>
                  </a>
                </div>
              </div>

              <div
                className="feature-col img img--meisje-kijkt-omhoog"
                title="Meisje lacht en kijkt omhoog"
                style={{
                  backgroundImage: `url('/assets/images/meisje-kijkt-omhoog-1.jpg'`,
                }}
              >
                <div className="overlay"></div>
                <blockquote class="blockquote ">
                  Ben jij HBO-student?
                </blockquote>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="padding">
            <span className="slogan h2">
              <span className="werken">Werken in het onderwijs.</span> De baan
              van het leven.
            </span>
          </div>
        </section>
      </>
    </Layout>
  )
}

export default IndexPage
